<template>
	<div>
		<h1>This is settings page</h1>
	</div>
</template>

<script>
export default {
	name: 'Settings',

	data() {
		return {}
	},

	mounted() {},

	methods: {},
}
</script>

<style lang="scss" scoped></style>
